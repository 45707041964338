.miamiContainer {
  padding: 70px 60px;
}
.imageContainer {
  width: 350px;
  height: 300px;
  top: 0;
  right: 0;
  margin: 20px 70px 0px 0px;
}
.imageTag {
  width: 150px;
  height: 150px;
  position: absolute;
  left: -75;
  bottom: -55;
}
.dogName {
  font-size: 58px;
}
.dogStatus {
  font-size: 30px;
}
.vaccinationTag {
  padding: 10px 30px;
  font-size: 18px;
  width: max-content;
}
.content {
  width: 600px;
  font-size: 18px;
}
.detailHeading {
  font-size: 20px;
}
.detailContent {
  font-size: 18px;
}
.polygonLeft {
  height: 120px;
  position: absolute;
  bottom: 40%;
  right: -65;
  transform: rotate(230deg);
}
.polygonBottom {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (max-width: 1150px) {
  .imageContainer {
    width: 250px;
    height: 200px;
  }
  .imageTag {
    width: 130px;
    height: 130px;
    left: -70;
    bottom: -50;
  }
  .polygonLeft {
    bottom: 53%;
  }
}

@media screen and (max-width: 900px) {
  .imageContainer {
    width: 230px;
    height: 180px;
  }
  .imageTag {
    width: 100px;
    height: 100px;
    left: -50;
    bottom: -40;
  }
  .dogName {
    font-size: 48px;
  }
  .dogStatus {
    font-size: 20px;
  }
  .vaccinationTag {
    padding: 8px 20px;
    font-size: 14px;
  }
  .vaccinationTag img {
    height: 30px;
  }
  .content {
    width: 500px;
    font-size: 14px;
  }
  .detailHeading {
    font-size: 16px;
  }
  .detailContent {
    font-size: 14px;
  }
}

@media screen and (max-width: 770px) {
  .imageContainer {
    width: 280px;
    height: 230px;
    top: 0;
    left: 35%;
    margin: 20px 0px 0px;
  }
  .imageTag {
    width: 130px;
    height: 130px;
    position: absolute;
    left: 30%;
    bottom: -65;
  }
  .miamiContainer {
    padding-top: 250px;
  }
  .content {
    width: 450px;
  }
}

@media screen and (max-width: 700px) {
  .imageContainer {
    left: 30%;
  }
}

@media screen and (max-width: 640px) {
  .imageContainer {
    left: 25%;
    width: 230px;
    height: 180px;
  }
  .imageTag {
    width: 100px;
    height: 100px;
    bottom: -50;
  }
  .content {
    width: 100%;
  }
  .items {
    justify-content: space-between;
  }
  .miamiContainer {
    padding: 250px 30px 70px;
  }
  .vaccinationTag img {
    height: auto;
  }
}

@media screen and (max-width: 500px) {
  .imageContainer {
    left: 20%;
  }
  .content {
    width: 100%;
  }
  .items {
    justify-content: space-between;
  }
  .miamiContainer {
    padding: 250px 30px 70px;
  }
  .vaccinationTag {
    width: auto;
  }
}
