.application {
  flex-direction: column;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-order-form {
  background-color: #fff;
  height: 100%;
}

.test-info {
  height: calc(50% - 100px);
  padding: 25px 0;
}

.submit {
  position: absolute;
  bottom: 0;
  height: 45px;
  padding: 20px 0;
  width: 100%;
  background-color: white;
  border-top-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-top-width: thin;
}

.submit-icon {
  position: absolute;
  top: 9px;
  left: 12px;
  display: block;
}

.container__content {
  width: 100%;
  position: absolute;
  /* top: 65px; */
  bottom: 85px;
  overflow-y: auto;
}

.ajax-loader {
  position: absolute;
  top: 50%;
  left: 60%;
  transform-origin: 50% 50%;
  transform: rotate(90deg) translate(-50%, 0%);
  font-size: 50px;
  width: 1em;
  height: 3em;
  color: #d31145;
}
@media screen and (max-width: 950px) {
  .ajax-loader .ajax-loader {
    left: 70%;
  }
}
@media screen and (max-width: 500px) {
  .ajax-loader .ajax-loader {
    left: 85%;
  }
}
.ajax-loader .paw {
  width: 1em;
  height: 1em;
  animation: 2050ms pawAnimation ease-in-out infinite;
  opacity: 0;
}
.ajax-loader .paw svg {
  width: 100%;
  height: 100%;
}
.ajax-loader .paw .icon {
  fill: currentColor;
}
.ajax-loader .paw:nth-child(odd) {
  transform: rotate(-10deg);
}
.ajax-loader .paw:nth-child(even) {
  transform: rotate(10deg) translate(125%, 0);
}
.ajax-loader .paw:nth-child(1) {
  animation-delay: 1.25s;
}
.ajax-loader .paw:nth-child(2) {
  animation-delay: 1s;
}
.ajax-loader .paw:nth-child(3) {
  animation-delay: 0.75s;
}
.ajax-loader .paw:nth-child(4) {
  animation-delay: 0.5s;
}
.ajax-loader .paw:nth-child(5) {
  animation-delay: 0.25s;
}
.ajax-loader .paw:nth-child(6) {
  animation-delay: 0s;
}
.no-cssanimations .ajax-loader .paw {
  opacity: 1;
}

@keyframes pawAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}/*# sourceMappingURL=Application.css.map */