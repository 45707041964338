.thing {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card {
  padding: 15px;
  margin: 15px 0;
  background-color: 'white';
  box-shadow: cubic-bezier(0.4, 0, 0.2, 1);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden;
}

span.price-title {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1.7142857142857142rem;
  line-height: 1.334;
  letter-spacing: 0em;
  display: block;
}

.price {
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 3.4285714285714284rem;
  line-height: 1.167;
  letter-spacing: 0em;
  margin-bottom: 0.35em;
}

a.learn-more {
  margin: 0;
  color: #1976d2;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  text-decoration-color: rgba(25, 118, 210, 0.4);
}

button.price-select {
  display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 5px 15px;
    border-radius: 4px;
    -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(25, 118, 210, 0.5);
    color: #1976d2;
}

.card-header-root {
  display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px;
}

.card-header-content {
  flex: 1 1 auto;
}

@media screen and (min-width: 768px) {
  .thing {
    flex-direction: row;
  }
  .card {
    display: inline-block;
    max-width: 31%;
    margin: unset;
  }
}



* {
  box-sizing: border-box;
}

/* Create three columns of equal width */
.columns {
  cursor: pointer;
  float: left;
  width: 33.3%;
  padding: 8px;
}

/* Style the list */
.price2 {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

/* Add shadows on hover */
.price2:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

/* Pricing header */
.price2 .header {
  background-color: #111;
  color: white;
  font-size: 25px;
}

/* List items */
.price2 li {
  border-bottom: 1px solid #eee;
  padding: 20px;
  text-align: center;
}

span.highlight {
  color: #0071bc;
}

/* Grey list item */
.price2 .grey {
  background-color: #eee;
  font-size: 20px;
}

/* The "Sign Up" button */
.button {
  cursor: pointer;
  background-color: #04AA6D;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

.button:hover {
  color: white;
}

/* Change the width of the three columns to 100%
(to stack horizontally on small screens) */
@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}
