#header {
  position: relative;
}
.banner-section.intro {
  position: relative;
  z-index: 999;
  background: rgba(0,0,0,0.7);
  height: auto;
}
.intro {
  display: table;
  width: 100%;
  padding: 0;
  /* background: url(public/img/service-dog.jpeg) center center no-repeat; */
  background-color: #e5e5e5;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}
.intro h1 {
  font-family: "Raleway", sans-serif;;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 10px;
  background: linear-gradient(to right, #b0d6ff 0%, #6372ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
header .intro-text {
  z-index: 9;
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}
video {
  background-size: cover;
    bottom: 0;
    height: auto;
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;
    width: auto;
    z-index: 0;
    display: inline-block;
    vertical-align: baseline;
}

/* @media (min-aspect-ratio: 16/9) { */
/*   video #header .intro { */
/*     width: 100%; */
/*     height: auto; */
/*   } */

/* } */
/* @media (max-aspect-ratio: 16/9) { */
@media screen and (max-width: 1024px) {
  header .intro-text {
    z-index: 9;
    padding-top: 290px;
    padding-bottom: 100px;
    text-align: center;
  }
  .intro h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 70px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 12px;
  }
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  .intro p {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  header .intro-text {
    z-index: 9;
    padding-top: 230px;
    padding-bottom: 80px;
    text-align: center;
  }
  .intro h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  .intro p {
    color: #fff;
    font-size: 18px;
    line-height: 22px;
    margin: 0 auto;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 480px) {
  video #header .intro {
    width: auto;
    height: 100%;
  }
  header .intro-text {
    z-index: 9;
    padding-top: 130px;
    padding-bottom: 70px;
    text-align: center;
  }
  .intro h1 {
    font-family: "Raleway", sans-serif;
    color: #fff;
    font-size: 32px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 10px;
  }
  .intro h1 span {
    font-weight: 800;
    color: #5ca9fb;
  }
  .intro p {
    color: #fff;
    font-size: 14px;
    line-height: 18px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}
