.ajax-loader {
  position: absolute;
  top: 50%;
  left: 60%;
  transform-origin: 50% 50%;
  transform: rotate(90deg) translate(-50%, 0%);
  font-size: 50px;
  width: 1em;
  height: 3em;
  color: #d31145;
}
@media screen and (max-width: 950px) {
  .ajax-loader {
    left: 70%;
  }
}
@media screen and (max-width: 500px) {
  .ajax-loader {
    left: 85%;
  }
}
#svg-sprite {
  position: absolute;
}
.ajax-loader .paw {
  width: 1em;
  height: 1em;
  animation: 2050ms pawAnimation ease-in-out infinite;
  opacity: 0;
}
.ajax-loader .paw svg {
  width: 100%;
  height: 100%;
}
.ajax-loader .paw .icon {
  fill: currentColor;
}
.ajax-loader .paw:nth-child(odd) {
  transform: rotate(-10deg);
}
.ajax-loader .paw:nth-child(even) {
  transform: rotate(10deg) translate(125%, 0);
}
.ajax-loader .paw:nth-child(2) {
  animation-delay: 2s;
}
.ajax-loader .paw:nth-child(3) {
  animation-delay: 1.75s;
}
.ajax-loader .paw:nth-child(4) {
  animation-delay: 1.5s;
}
.ajax-loader .paw:nth-child(5) {
  animation-delay: 1.25s;
}
.ajax-loader .paw:nth-child(6) {
  animation-delay: 1s;
}
.ajax-loader .paw:nth-child(7) {
  animation-delay: 0.75s;
}
.ajax-loader .paw:nth-child(8) {
  animation-delay: 0.5s;
}
.ajax-loader .paw:nth-child(9) {
  animation-delay: 0.25s;
}
.ajax-loader .paw:nth-child(10) {
  animation-delay: 0s;
}
.no-cssanimations .ajax-loader .paw {
  opacity: 1;
}

@keyframes pawAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
} /*# sourceMappingURL=Loading.css.map */
