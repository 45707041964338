#menu {
  /* background-color: rgba(0, 0, 0, 0.2); */
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

#menu > a {
  color: #fff;
}
/* Navigation */
#menu {
  /* max-height: 81px; */
  padding: 15px;
  transition: all 0.8s;
}
#menu.navbar-default {
  /* background-color: #fff; */
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
#menu a {
  color: #fff;
}
#menu a.navbar-brand {
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}
#menu {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}

#menu > a {
  color: #fff;
}

.sign-in {
  padding: 8px 12px !important;
  border-radius: 25px !important;
}

@media screen and (max-width: 765px) {
  .show-large {
    display: none !important;
    visibility: hidden;
  }
}

.show-small {
  display: block !important;
}

@media screen and (min-width: 765px) {
  .show-small {
    display: none !important;
  }
}

.small-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
}
