.loading {
  animation: spin 3s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  30% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  55% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  80% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
