.outer {
  overflow: hidden !important;
  height: 100%;
}

.retrieveCard {
  animation: card1 2s;
  animation-delay: 2s;
}

.retrieveCard2 {
  animation: card2 1s;
}

@keyframes card1 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

@keyframes card2 {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.topCard {
  animation: topCard 1s;
}

@keyframes topCard {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.bottomCard {
  animation: bottomCard 1s;
}

@keyframes bottomCard {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.imgGrey {
  filter: grayscale(100%);
}
