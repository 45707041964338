.subText{
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #777777;
}

.input-image{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px dashed;
    border-color: #1976d2;
    border-radius: 4px;
    height: 125px;
}

.card-type h5{
    font-weight: 600;
    font-size: 17px;
    line-height: 130%;
    color: #777777;
}

.selected-button {
  height: 31px;
  background: linear-gradient(
      0deg,
      rgba(255, 153, 0, 0.8),
      rgba(255, 153, 0, 0.8)
    ),
    #ffffff;
  border: 1px solid #ff9900;
  box-sizing: border-box;
  border-radius: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.32px;
  color: #000000;
  cursor: pointer;
  padding: 22px 16px;
  width: 48%;
  
}

.unselected-button {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.32px;
  color: #000000;
  padding: 22px 16px;
  width: 48%;
  height: 31px;
  background: #e3e3e3;
  border-radius: 100px;
  cursor: pointer;
}

.disabled{
    opacity: 0.4;
}

.closeBtn{
  position: absolute;
  margin-top: 0;
  margin-left: 12rem;
  cursor: pointer;
  z-index: 999;
}
