.ajax-loader {
  position: absolute;
  top: 50%;
  left: 60%;
  transform-origin: 50% 50%;
  transform: rotate(90deg) translate(-50%, 0%);
  font-size: 50px;
  width: 1em;
  height: 3em;
  color: #d31145;

  @media screen and (max-width: 950px) {
    .ajax-loader {
      left: 70%;
    }
  }

  @media screen and (max-width: 500px) {
    .ajax-loader {
      left: 85%;
    }
  }

  .paw {
    width: 1em;
    height: 1em;
    animation: 2050ms pawAnimation ease-in-out infinite;
    opacity: 0;

    svg {
      width: 100%;
      height: 100%;
    }

    .icon {
      fill: currentColor;
    }

    &:nth-child(odd) {
      transform: rotate(-10deg);
    }

    &:nth-child(even) {
      transform: rotate(10deg) translate(125%, 0);
    }

    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-delay: #{(($i * -1)+6) * 0.25}s;
      }
    }

    .no-cssanimations & {
      opacity: 1;
    }
  }
}

@keyframes pawAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
