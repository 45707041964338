.parent {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
}

.overlay::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 18px;
  background-image: linear-gradient(
    to bottom,
    rgba(170, 184, 190, 0),
    rgba(0, 247, 132, 0.8)
  );
  animation: scanning 0.8s linear infinite;
}

@keyframes scanning {
  100% {
    transform: translate(0, 170px);
  }
}
