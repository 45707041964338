.loaderContainer {
  padding: 70px 60px;
}
.loaderImage {
  width: auto;
}
.content {
  font-size: 30px;
}

@media screen and (max-width: 700px) {
  .loaderImage {
    width: 450px;
  }
}

@media screen and (max-width: 550px) {
  .loaderContainer {
    padding: 70px 40px;
  }
  .loaderImage {
    width: 350px;
  }
  .loaderContent {
    font-size: 20px;
  }
}

@media screen and (max-width: 440px) {
  .loaderImage {
    width: 270px;
  }
}
