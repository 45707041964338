section {
  padding: 40px 0;
}

section:last-child {
  padding-bottom: 0;
}
@media screen and (max-width: 400px) {
  #menu a.navbar-brand {
    font-size: 14px;
  }
  #features {
    padding: 20px;
    /* width: 111%; */
  }
  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    /* width: 111%; */
  }

  #portfolio {
    /* width: 110%; */
  }
}
body {
  background-color: rgba(245, 245, 245);
}
body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
  font-size: 16px;
}
#root {
  height: 100%;
}
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

@media screen and (min-width: 480px) {
  h2 {
    text-transform: uppercase;
  }
}
h2 {
  margin: 0 0 20px;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
ul,
ol {
  list-style: none;
}
ul,
ol {
  padding: 0;
  webkit-padding: 0;
  moz-padding: 0;
}
hr {
  height: 1px;
  opacity: .25;
  width: 100%;
  margin: 1rem 0;
  text-align: center;
  position: relative;
  background: black;
  margin-bottom: 20px;
  border: 0;
}
.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

/* About Section */
#about {
  padding: 100px 0;
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px;
}
#about h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#about h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}
#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  list-style: none;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}


/* Services Section */
#services {
  padding: 100px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: #fff;
}
#services .service-desc {
  margin: 10px 10px 20px;
}
#services h2 {
  color: #fff;
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#services p {
  color: rgba(255, 255, 255, 0.75);
}
#services .service-desc {
  margin-bottom: 40px;
}


/* Portfolio Section */
#portfolio {
  padding: 100px 0;
}
.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(99, 114, 255, 0.8) 0%,
    rgba(92, 169, 251, 0.8) 100%
  );
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}

/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: #f6f6f6;
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-image {
  float: left;
  margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}

/* Team Section */
#team {
  padding: 100px 0;
}
#team h4 {
  margin: 5px 0;
}
#team .team-img {
  width: 240px;
}
#team .thumbnail {
  background: transparent;
  border: 0;
}
#team .thumbnail .caption {
  padding: 10px 0 0;
  color: #888;
}

/* Footer Section*/
#footer {
  background: #f6f6f6;
  padding: 30px 0;
}
#footer p {
  color: #888;
  font-size: 14px;
}
#footer a {
  color: #608dfd;
}
#footer a:hover {
  border-bottom: 2px solid #608dfd;
}

@media screen and (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
  #menu a.navbar-brand {
    font-size: 14px;
  }
}
