.main {
  position: relative;
  z-index: 2;
  max-width: 1064px;
  margin: 0 auto;
  width: 100%;
}

.overlay-bg {
  background: rgba(0,139,174,.7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 1;
}
/* .overlay{ */
/*   position: absolute; */
/*   top: 0; */
/*   left: 0; */
/*   width: 100%; */
/*   height: 100%; */
/*   background: rgba(48,25,52,0.75); */
/* } */
.overlay h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 82px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.overlay h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.overlay p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
.overlay .intro-text {
  padding-top: 350px;
  padding-bottom: 200px;
  text-align: center;
}

video {
  object-fit: cover;
  background-size: cover;
    bottom: 0;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    right: 0;
    width: auto;
    z-index: 0;
    display: inline-block;
    vertical-align: baseline;
}
