#features {
  padding-top: 30px;
  background: #f6f6f6;
}
#features i.fa {
  font-size: 38px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}

#features i.fa svg path {
  fill: #fff;
}
#features i.fa svg {
  height: 48px;
  width: 48px;
}
